import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import SalesGraph from "../salesCharts/SalesGraph";
import { GrDownload } from "react-icons/gr";
import { MdDateRange } from "react-icons/md";
import Fade from "react-bootstrap/Fade";
import DatePickerSelect from "../../products/overviewProduct/date.picker";
import ReactButtonSlider from "@gunawanedy/react-button-slider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { startLoader, stopLoader } from "../../../lib/global";
import {
  exportSalesMerchantOrder,
  getAllSalesOrder,
} from "../../../redux/actions/merchant/orderSales.action";
import ListButtonItem from "./listButtonItem";

const dashboardReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState();
  const [data, setData] = useState([]);
  const [categoriesList, setCategoriesList] = useState([
    "Orders - Buy Now",
    "Orders - Team Purchase",
  ]);
  const [display, setDisplay] = useState("none");

  // DateFilterDropdown state
  const [statusDate, setStatusDate] = useState("Year");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(new Date(endDate) - 1000 * 60 * 60 * 24 * 365)
  );
  // DateFilterDropdown state

  useEffect(() => {
    let pathName = history.location.pathname.slice(20);
    const breadcrumb = [{ label: `Dashboard`, url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    const filteringData = {
      status: statusDate,
      startDate,
      endDate,
      categories: categoriesList,
      downloadFile: false,
    };

    apiData(filteringData);
  }, [startDate, categoriesList]);

  const apiData = (filteringData) => {
    startLoader();
    const response = dispatch(getAllSalesOrder(filteringData));
    response
      .then(({ data }) => {
        setData(data["data"]);
        setChartData(data["chartData"]);

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ error }, "error");
      });
  };

  const handleExportData = () => {
    console.log("button to exporttttttt");
    const filteringData = {
      status: statusDate,
      startDate,
      endDate,
      categories: categoriesList,
    };
    dispatch(exportSalesMerchantOrder(filteringData))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}sales-report.xlsx`);
        // // Append to html link element page
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        console.log({ err });
        alert("error .somthing is wrong");
        stopLoader();
      });
    apiData(filteringData);
  };

  return (
    <React.Fragment>
      <div className="mx-3 pr-3 py-2 rounded bg-white d-flex flex-row justify-content-end">
        <div className="row flex-wrap">
          <div className="row flex-nowrap ml-2 py-2 font-weight-bold">
            <span className="badge">Data Period</span>
          </div>
          <div className="col mx-1">
            <div
              className="d-flex justifly-content-center align-items-center pointer"
              onClick={() => {
                setOpen(!open);
                if (!open) setDisplay("block");
                else setDisplay("none");
              }}
            >
              <div
                className="d-flex rounded border border-muted px-2 py-2"
                // onClick={() => setOpen(!open)}
              >
                <MdDateRange className="ml-1" />
                <span className="font-weight-bold badge">By {statusDate}:</span>
                <span className="text-muted badge">
                  {moment(startDate).format("ll")} -{" "}
                  {moment(endDate).format("ll")}
                </span>
              </div>
            </div>
            <Fade
              in={open}
              className="rounded align-items-center border border-muted mt-2 bg-white"
            >
              <div
                id="example-fade-text"
                onMouseLeave={() => {
                  setDisplay("none");
                  setOpen(false);
                }}
              >
                <DatePickerSelect
                  statusDate={statusDate}
                  setStatusDate={setStatusDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
            </Fade>
          </div>
        </div>
        <div
          className="d-flex flex-row flex-nowrap font-weight-bold px-4 btn border border-muted align-items-center btn-outline-dark pointer h-15"
          onClick={handleExportData}
        >
          <span className="badge badge-light">
            <GrDownload />
          </span>
          <span className="badge">Export Data</span>
        </div>
      </div>
      <div className="mt-3 mx-3 py-3 rounded bg-white d-flex flex-row justify-content-end">
        <div className="col-12 d-flex flex-column px-1 p-0">
          <div className="text-m font-weight-bold mx-2 rounded align-items-center">
            <h5 className="font-weight-bold">
              <span className="badge">Key Metrics</span>
            </h5>
          </div>
          <div className="text-m font-weight-bold mx-2 rounded align-items-center">
            <ReactButtonSlider className="dashboard_main col-12 flex-wrap p-2 bg-dark">
              {data?.map((item, idx) => {
                return (
                  <div key={idx}>
                    <ListButtonItem
                      item={item}
                      statusDate={statusDate}
                      index={idx}
                      categoriesList={categoriesList}
                      setCategoriesList={setCategoriesList}
                    />
                  </div>
                );
              })}
            </ReactButtonSlider>
          </div>
          <div className="d-flex text-m font-weight-bold mx-2 rounded align-items-center">
            <h5 className="font-weight-bold">
              <span className="badge">Trend Chart of Each Metric</span>
            </h5>
          </div>
          <div className="px-2">
            <div className="dahsboard_header_tab">
              <SalesGraph
                series={chartData?.series}
                categories={chartData?.categories}
                type="line"
              ></SalesGraph>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .dahsboard_header_tab {
          // background: #000000d6;
        }
        .tab_list {
          list-style: none;
        }
        .dahsboard_header_tab > ul {
          flex-wrap: wrap;
        }
        .list_card {
          padding: 0px 8px;
          display: flex;
          min-height: 80px;
          min-width: 220px;
          border-radius: 3px;
          box-shadow: 0 0 2px 0px #0000004f;
        }
        .card_item {
          flex-grow: 10;
          text-align: left;
        }
        .card_icon {
          flex-grow: 2;
          display: flex;
          align-items: center;
        }
        .date_picker {
        }
        #example-fade-text {
          display: ${display};
          // display: none;
          position: absolute;
          z-index: 1020;
        }

        .dashboard_container {
        }
        .dashboard_menu {
          display: flex;
          flex-direction: wrap;
          background: rgb(210, 210, 210);
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
        .dashboard_main {
          display: flex;
          justify-content: between;
          align-items: center;
          float: left;
        }
      `}</style>
    </React.Fragment>
  );
};

export default dashboardReport;

const colors = ["#247BA0", "#f48024"];

// const gradientList = [
// "linear-gradient(270deg, #8e50ff 0, #6946ff 25%, #3c3cf2 50%, #0032da 75%, #0029c2 100%)",
// "linear-gradient(270deg, #776d4a 0, #706b46 7.14%, #676a42 14.29%, #5d673e 21.43%, #51653a 28.57%, #446137 35.71%, #345e34 42.86%, #1f5932 50%, #005431 57.14%, #005033 64.29%, #004c36 71.43%, #00483a 78.57%, #004540 85.71%, #004246 92.86%, #003f4d 100%)",
// "linear-gradient(270deg, #c4d472 0, #cdcd68 6.25%, #d6c55e 12.5%, #ddbc56 18.75%, #e3b24e 25%, #e9a647 31.25%, #ed9941 37.5%, #f08a3e 43.75%, #f2793c 50%, #f3673d 56.25%, #f45442 62.5%, #f3404a 68.75%, #f22753 75%, #ef005f 81.25%, #eb006c 87.5%, #e5007a 93.75%, #dd0089 100%)",
// "linear-gradient(70deg, #ff80d1 0, #ff76d8 10%, #ff6cdf 20%, #f562e6 30%, #db5aec 40%, #bc53f2 50%, #954ff8 60%, #604ffd 70%, #0051ff 80%, #0054ff 90%, #0056ff 100%)",
// "linear-gradient(70deg, #ff80d1 0, #ff76d8 10%, #ff6cdf 20%, #f562e6 30%, #db5aec 40%, #bc53f2 50%, #954ff8 60%, #604ffd 70%, #0051ff 80%, #0054ff 90%, #0056ff 100%)",
// "linear-gradient(70deg, #ff80d1 0, #ff76d8 10%, #ff6cdf 20%, #f562e6 30%, #db5aec 40%, #bc53f2 50%, #954ff8 60%, #604ffd 70%, #0051ff 80%, #0054ff 90%, #0056ff 100%)",
// ];
