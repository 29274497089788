import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { makeStyles } from "@mui/styles";
import SelectDropdown from "../../../../components/select";

import React, { useEffect, useState } from "react";
import { Div } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../../components/Images/Image";
import Box from "@mui/material/Box";

import Paper from "@mui/material/Paper";
import SelectInput from "@mui/material/Select/SelectInput";
import merchantSidebarList from "../../../../components/sidebar/MerchantSidebar";
import { orderStatuses } from "../../../../shared/language";
import { getOrderById } from "../../../../redux/actions/order.action";
import { useDispatch } from "react-redux";
import { getAllDatesInRange } from "react-multi-date-picker";
import { SHOW_TRANSACTION_HISTORY } from "../../../../lib/constant";
import { open_dialog } from "../../../../lib/global";
import { breadcrumbAction } from "../../../../redux/actions/config.action";

const useStyles = makeStyles({
  timelineitem: {
    // minHeight:"0px"
  },
  timelineContentOppositeContainer: {
    maxWidth: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  timing: {
    marginTop: "-5px",
    fontSize: "0.6rem",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
  mainText: {
    fontSize: "0.8rem",
  },
});
function MerchantViewOrder() {
  const [fullData, setFullData] = useState({});
  console.log(fullData?.product?.brand);

  const [orderStatus, setOrderStatus] = useState();
  const [viewOrderDelivery, setViewOrderDelivery] = useState(false);
  const [editOrderShipped, setEditOrderShipped] = useState(false);

  const handleShowTrasactionHistory = () => {
    open_dialog(SHOW_TRANSACTION_HISTORY, {});
  };
  const { mid, merchantName, id } = useParams();
  const history = useHistory();
  const back_to_allorder = () => {
    history.push("/orders/all-order");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const breadcrumb = [
      { label: "Orders", url: `/merchant-center/profiles` },
      {
        label: merchantName,
        url: `/merchant-center/profiles/${mid}/${merchantName}`,
      },
      { label: `Order ID ${id}`, url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      dispatch(getOrderById(id))
        .then((data) => {
          if (data && data.data && data.data.data) {
            // console.log(data?.data?.data[0]);
            setFullData(data?.data?.data);
          }
          // setFullData()
          console.log(
            data?.data?.data,
            "lllllllllllllllllllllllllllllllllllllllll"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className="d-flex  position-relative  mx-4 ">
        <div className="col-8  pr-3">
          <div className="py-3">
            <div className="d-flex box justify-content-between  mb-2">
              <div>ORDER INFORMATION</div>
              <div
                className="d-flex align-items-center "
                onClick={() => handleShowTrasactionHistory()}
              >
                <Image src="/trans.png" alt="image" width={18} height={18} />
                <div className="pl-2">Transaction History</div>
              </div>
            </div>
            <div className="card p-3">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        Product
                      </TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        Price
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="right"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        <div>Subtotal</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {[1].map((row, index) => ( */}
                    <TableRow
                      // key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="left"
                        colSpan={5}
                      >
                        <div className="d-flex">
                          <Image
                            // src="/images/shoes.jpeg"
                            src={fullData?.productImages || ""}
                            className="order_page_product_image"
                            alt="img"
                          />
                          <div className="px-2">
                            <div>{fullData?.productName || "N/A"}</div>
                            <div className="text-muted">
                              {`variation: ${
                                fullData?.variation?.name || "N/A"
                              }`}

                              {/* white */}
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${
                          fullData?.price || "N/A"
                        }.00`}
                      </TableCell>
                      <TableCell
                        className="td_cell "
                        component="td"
                        align="right"
                      >
                        {fullData?.quantity || 0}
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${
                          (fullData?.price || 0) * (fullData?.quantity || 0)
                        }.00`}
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                  <TableRow
                    sx={{
                      " td, th": {
                        border: 0,
                        "padding-top": 0,
                        "padding-bottom": 0,
                        "font-size": "1rem",
                        "font-weight": "700",
                      },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={8}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      " td, th": { border: 0, "padding-top": 1 },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={7}
                    >
                      <div>Subtotal :</div>
                      <div>Shipping :</div>
                      <div>Fee & Charges :</div>
                      <div className="py-3">Total Income :</div>
                    </TableCell>
                    <TableCell className="td_cell" component="td" align="right">
                      <div>
                        {`${fullData?.currencyName || "RM"}${
                          (fullData?.price || 0) * (fullData?.quantity || 0)
                        }.00`}
                      </div>
                      <div>{`${fullData?.currencyName || "RM"}${
                        fullData?.shippingDetails?.shippingCharge || "0"
                      }.00`}</div>
                      <div>{`${fullData?.currencyName || "RM"}${
                        fullData?.shippingDetails?.feeAndCharges || "0"
                      }.00`}</div>
                      <div
                        className="py-3 text-success "
                        style={{ fontSize: "1.2rem" }}
                      >
                        {`${fullData?.currencyName || "RM"}${
                          (Number(fullData?.shippingDetails?.shippingCharge) ||
                            0) +
                          (fullData?.price || 0) * (fullData?.quantity || 0) +
                          (fullData?.shippingDetails?.feeAndCharges || 0)
                        }.00`}
                      </div>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="py-3">
            <div className="d-flex box justify-content-between mt-3 mb-2">
              <div>BUYER PAYMENT</div>
            </div>
            <div className="card p-3">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                        colSpan={8}
                      >
                        <div>Subtotal</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        " td, th": {
                          border: 0,
                          "padding-top": "1rem",
                          width: "8rem",
                        },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                        colSpan={5}
                      >
                        <div>Merchandise Subtotal :</div>
                        <div>Shipping :</div>
                        <div>FlexShop Voucher :</div>
                        <div>Seller Voucher :</div>
                        <div>Credit/Debit Card Promotion :</div>
                        <div className="py-3">Total Income :</div>
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        <div>
                          {`${fullData?.currencyName || "RM"}${
                            (fullData?.price || 0) * (fullData?.quantity || 0)
                          }.00`}
                        </div>
                        <div>
                          {`${fullData?.currencyName || "RM"}${
                            fullData?.shippingDetails?.shippingCharge || "0"
                          }.00`}{" "}
                        </div>
                        <div>
                          {`${fullData?.currencyName || "RM"}${
                            fullData?.shippingDetails?.uu2Voucher || "0"
                          }.00`}{" "}
                        </div>
                        <div className="text-muted">
                          {`${fullData?.currencyName || "RM"}${
                            fullData?.shippingDetails?.sellerVoucher || "0"
                          }.00`}{" "}
                        </div>
                        <div className="text-muted">
                          {`${fullData?.currencyName || "RM"}${
                            fullData?.shippingDetails
                              ?.creaditDebitCardVoucher || "0"
                          }.00`}
                        </div>
                        <div className="py-3" style={{ fontSize: "1.2rem" }}>
                          {`${fullData?.currencyName || "RM"}${
                            (fullData?.price || 0) * (fullData?.quantity || 0) +
                            Number(
                              fullData?.shippingDetails?.shippingCharge || 0
                            ) +
                            (fullData?.shippingDetails?.uu2Voucher || 0) +
                            (fullData?.shippingDetails?.sellerVoucher || 0) +
                            (fullData?.shippingDetails
                              ?.creaditDebitCardVoucher || 0)
                          }.00`}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="py-3">
            <div className="d-flex box justify-content-between mt-3 mb-2">
              LOGISTIC INFORMATION
            </div>
            <div className="card py-3 ">
              <div className="d-flex position-relative">
                <div className="col-11 d-flex flex-column justify-content-center  align-items-center">
                  <div style={{ fontSize: "1.4rem", fontWeight: "700" }}>
                    Order Shipped
                  </div>
                  <div>Saturday 11/02/2021 at 11:29am</div>
                </div>
                <div className="d-flex mb-3 position-absolute r-0 top-0">
                  {!editOrderShipped ? (
                    <button
                      className=" btn border input-inside-text  mx-2"
                      onClick={() => setEditOrderShipped(true)}
                    >
                      Edit
                    </button>
                  ) : (
                    <>
                      <button
                        className=" btn border input-inside-text  mx-2"
                        onClick={() => setEditOrderShipped(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className=" btn border btn-dark input-inside-text  "
                        onClick={() => setEditOrderShipped(false)}
                        visible={String(editOrderShipped)}
                      >
                        save
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 my-3 mb-3 d-flex justify-content-center">
                <div className=" d-flex flex-column align-items-center  ">
                  <div>
                    <TimelineDot />
                  </div>
                  <div className={classes.mainText}>Order Paid</div>
                  <div className={`text-muted px-3 ${classes.timing}`}>
                    02/03/2022 14:00pm
                  </div>
                </div>
                <div className=" d-flex flex-column align-items-center  ">
                  <div>
                    <TimelineDot />
                  </div>
                  <div className={classes.mainText}>Preparing TO Ship</div>
                  <div className={`text-muted px-3 ${classes.timing}`}>
                    02/03/2022 14:00pm
                  </div>
                </div>
                <div className=" d-flex flex-column align-items-center  ">
                  <div>
                    <TimelineDot />
                  </div>
                  <div className={classes.mainText}>Order Shiipped</div>
                  <div className={`text-muted px-3 ${classes.timing}`}>
                    02/03/2022 14:00pm
                  </div>
                </div>
                <div className=" d-flex flex-column align-items-center  ">
                  <div>
                    <TimelineDot />
                  </div>
                  <div className={classes.mainText}>Order Delivered</div>
                  <div className={`text-muted px-3 ${classes.timing}`}>
                    02/03/2022 14:00pm
                  </div>
                </div>
                <div className=" d-flex flex-column align-items-center  ">
                  <div>
                    <TimelineDot />
                  </div>
                  <div className={classes.mainText}>Order Placed</div>
                  <div className={`text-muted px-3 ${classes.timing}`}>
                    02/03/2022 14:00pm
                  </div>
                </div>
              </div>
              <div
                className="my-3 d-flex justify-content-center "
                visible={String(editOrderShipped)}
              >
                <label
                  className={`col-3 text-center m-0 px-2 py-3 ${classes.mainText}`}
                >
                  Logistic Status
                </label>
                <div className=" col-9 form-group">
                  <SelectDropdown
                    options={orderStatuses}
                    placeholder="Select status"
                    value={orderStatus}
                    onChange={setOrderStatus}
                  />
                  <div className={`text-muted ${classes.timing} py-1`}>
                    *Please note that you are only allowed to edit status if
                    only logistic partner unable to update the status or system
                    error
                  </div>
                </div>
              </div>
              <div>
                <Timeline align="left">
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot className="bg-success" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={`text-success ${classes.mainText}`}>
                        Seller is prepering to ship your parcel
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.mainText}>
                        Parcel has been picked up by courier
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot className="" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.mainText}>
                        Parcel has departed from station
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot className="" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.mainText}>
                        Parcel has arrived at Station: PCH Hub
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot className="" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.mainText}>
                        Parcel is out for delivery
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem style={{ minHeight: "0px" }}>
                    <TimelineOppositeContent
                      className={classes.timelineContentOppositeContainer}
                    />
                    <TimelineSeparator>
                      <TimelineDot className="" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.mainText}>
                        Parcel has been delivered
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
          </div>
          <div className="py-3">
            <div className="d-flex box justify-content-between mt-3 mb-2">
              <div>CONSUMER INFORMATION</div>
            </div>
            <div className="card p-4 ">
              <div className="d-flex justify-content-between">
                <div className="col-4 p-0">
                  <div className="pb-3">Personal detail</div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Name:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.consumerName || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Email:{" "}
                    </label>
                    <div className="col-8 text-nowrap p-0">
                      {fullData?.consumer?.email || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Phone:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.phoneNumber || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-7 p-0 ">
                  <div className=" p-0 mb-3">
                    <div className="pb-3">Billing Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.billingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" p-0 mt-2">
                    <div className="pb-3">Shipping Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.shippingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 position-absolute  p-3 r-0 card my-5 ">
          <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">status</div>
              <div className="font-weight-bold" style={{ fontSize: "1rem" }}>
                To Ship
              </div>
            </div>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Order ID</div>
              <div className="">
                {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")}
                {/* {fullData?.orderId || "N/A"} */}
              </div>
            </div>
          </div>

          <div className="font-weight-bolder py-1">Payment</div>
          <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Payment Method</div>
              <div className="">
                {fullData?.paymentMethods || "N/A"}
                {/* Creadit/Debit Card */}
              </div>
            </div>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Payment Date</div>
              <div className="">{fullData?.paymentDate || "N/A"}</div>
            </div>
          </div>

          <div className="font-weight-bolder py-1">Shipping Details</div>
          <div className="mb-3" style={{ fontSize: "0.8rem" }}>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Merchant</div>
              <div className="">
                {fullData?.merchantName || "N/A"}

                {/* Nike eStore */}
              </div>
            </div>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Shipping</div>
              <div className="">
                {fullData?.shippingDetails?.shippingType || "N/A"}
              </div>
            </div>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Courier</div>
              <div className="">
                {fullData?.shippingDetails?.courierName || "N/A"}
              </div>
            </div>
            <div className="d-flex justify-content-between py-1">
              <div className="text-muted">Tracking Number</div>
              <div className="">
                {fullData?.shippingDetails?.trackingNo || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
        <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
          <button
            type="button"
            className="btn border input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          {/* <button
                        type="button"
                        className="btn border btn-dark input-inside-text mr-2"
                        onClick={history.goBack}
                    >
                        Create
                    </button> */}
        </div>
      </footer>
    </React.Fragment>
  );
}

export default MerchantViewOrder;
