export const handleDocumentTitleLogin = (role) => {
  if (role == "MERCHANT") {
    document.title = "Flex Merchant";
  } else {
    document.title = "Flex Shop";
  }
};

export const handleDocumentTitleLogout = () => {
  document.title = "Flex Shop";
};
