import { IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Image from "../../components/Images/Image";
import TextField from "../../components/formControl/input/TextField";
import * as api from "../../services/service";
import ablity from "../../helpers/abilityGuard";

const SeoSettingsPage = () => {
  const validate = Yup.object({
    title: Yup.string().required("Title Required!"),
    description: Yup.string().required("Description required!"),
    previewImage: Yup.string().required("Default seo image required!"),
    graphImage: Yup.string().required("Graph image required!"),
    twitterImage: Yup.string().required("Twitter image required!"),
    copyRightText: Yup.string().required("Copy right text required!"),
  });
  const [data, setData] = useState({});

  useEffect(() => {
    getTotallData();
  }, []);

  const getTotallData = () => {
    api
      .getSeoData()
      .then((res) => {
        if (res && res.data && res.data.data) {
          setData(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const updateSeoData = (data) => {
    const payload = {
      metatags: data.metatags,
      copyRightText: data.copyRightText,
      description: data.description,
      title: data.title,
      facebookImage: data.facebookImage,
      previewImage: data.previewImage,
      twitterImage: data.twitterImage,
      graphImage: data.graphImage,
    }
    api
      .setSeoData(payload)
      .then(() => {
        getTotallData();
      })
      .catch((err) => console.error(err));
  };
  return (
    <React.Fragment>
      <div className="container-fluid py-3">
        <h2 className="text-start pb-2">Metatags </h2>
        <Formik
          initialValues={data}
          validationSchema={validate}
          enableReinitialize={true}
          onSubmit={(values) => {
            updateSeoData(values);
          }}
        >
          {(formik) => (
            <div className="border bg-white py-3">
              <Form className="form">
                {/* --------------title--------------- */}
                <TextField
                  type="text"
                  label="Title"
                  name="title"
                  classes="col-md-8"
                  value={formik?.values.title}
                  onChange={formik.handleChange}
                  placeholder="title"
                />

                {/* ----------description------------- */}
                <TextField
                  type="text"
                  label="Description"
                  name="description"
                  classes="col-md-8"
                  textarea={true}
                  placeholder="description"
                />

                {/* ---------------preview image------------ */}
                <div className="og_image text-start">
                  <label
                    className="col-md-3 text-start"
                    htmlFor={"previewImage"}
                  >
                    Preview Image
                  </label>
                  <input
                    accept="image/*"
                    className={"image_input"}
                    name="previewImage"
                    id="icon-button-file"
                    type="file"
                  />

                  <label
                    className="col-md-9 text-start fit-content p-0"
                    htmlFor="icon-button-file"
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      style={{ borderRadius: 0 }}
                    >
                      <Image
                        src="/images/image_placeholder.png"
                        height="100px"
                        width="100px"
                        className="border-dashed"
                        alt="click here to upload image"
                      />
                    </IconButton>
                  </label>
                  <label className="col-auto text-start fit-content p-0">
                    {formik.values.previewImage ? (
                      <Image
                        src={formik.values.previewImage}
                        height="100px"
                        width="100px"
                        name="previewImage"
                        className="object-fit-contain"
                        alt="seo twitter image"
                      />
                    ) : (
                      <></>
                    )}
                  </label>
                </div>

                {/* -----------OG Imae */}
                <div className="og_image text-start">
                  <label className="col-md-3 text-start" htmlFor={"graphImage"}>
                    OG Image
                  </label>
                  <input
                    accept="image/*"
                    className={"image_input"}
                    name="graphImage"
                    id="icon-button-file"
                    type="file"
                  />

                  <label
                    className="col-md-9 text-start fit-content p-0"
                    htmlFor="icon-button-file"
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      style={{ borderRadius: 0 }}
                    >
                      <Image
                        src="/images/image_placeholder.png"
                        height="100px"
                        width="100px"
                        className="border-dashed"
                        alt="click here to upload image"
                      />
                    </IconButton>
                  </label>
                  <label className="col-auto text-start fit-content p-0">
                    {formik.values.graphImage ? (
                      <Image
                        src={formik.values.graphImage}
                        height="100px"
                        width="100px"
                        name="graphImage"
                        className="object-fit-contain"
                        alt="seo twitter image"
                      />
                    ) : (
                      <></>
                    )}
                  </label>
                </div>

                {/* -----------twitter image--------------- */}
                <div className="og_image text-start">
                  <label
                    className="col-md-3 text-start"
                    htmlFor={"twitterImage"}
                  >
                    Twitter Image
                  </label>
                  <input
                    accept="image/*"
                    className={"image_input"}
                    name="twitterImage"
                    id="icon-button-file"
                    type="file"
                  />

                  <label
                    className="col-auto text-start fit-content p-0"
                    htmlFor="icon-button-file"
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      style={{ borderRadius: 0 }}
                    >
                      <Image
                        src="/images/image_placeholder.png"
                        height="100px"
                        width="100px"
                        className="border-dashed"
                        alt="click here to upload image"
                      />
                    </IconButton>
                  </label>
                  <label className="col-auto text-start fit-content p-0">
                    {formik.values.twitterImage ? (
                      <Image
                        src={formik.values.twitterImage}
                        height="100px"
                        width="100px"
                        name="twitterImage"
                        className="object-fit-contain"
                        alt="seo twitter image"
                      />
                    ) : (
                      <></>
                    )}
                  </label>
                </div>

                {/* --------------copy right text----------------- */}
                <TextField
                  type="text"
                  label="Copy Right Text"
                  name="copyRightText"
                  classes="col-md-8"
                  placeholder="copyright text"
                />

                {/* --------------------submit button----------- */}
                {ablity({model:'Metatag', action:'update'}) == 1 && (
                    <button className="btn btn-primary m-3" type="submit">
                      Update
                    </button>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </div>
      <style jsx="true">{`
        .image_input {
          display: none;
        }
      `}</style>
    </React.Fragment>
  );
};

export default SeoSettingsPage;

const initialValues = {
  title: "FlexShop Online Store - Best deal happens when you buy together | flexshop-website.appsdelite.com",
  description:
    "FlexShop Online Store - Shop Exclusive Collections of Trending Fashion Clothing, Accessories & Footwear at uu2-website.appsdelite.com",
  defaultImage: "https://uu2-admin.appsdelite.com/images/preview-image.png",
  previewImage: "https://uu2-admin.appsdelite.com/images/preview-image.png",
  twitterImage: "https://uu2-admin.appsdelite.com/images/preview-image.png",
  copyRightText: "Copy Right 2021",
};
