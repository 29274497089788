import { IconButton, Pagination, Stack } from "@mui/material";
import Image from "../../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import moment from "moment";
import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
} from "../../../lib/global";
import "../../../static/css/orderCss/allOrder.css";
import {
  getAllOrderList,
  getOrderByStoreId,
} from "../../../redux/actions/order.action";
import { getLocalStorage } from "../../../lib/session";
import { ACCESS_DENIED, DELETE_ORDER } from "../../../lib/constant";
import { LOGIN_POSTER } from "../../../lib/config";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import decimalFormat from "../../../helpers/decimal.format";
import ablity from "../../../helpers/abilityGuard";
import { getAllOrderListByMerchant } from "../../../redux/actions/merchant/order.action";

function AllOrders(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [permissions, setPermissions] = useState(
    getLocalStorage("assignMethod")
  );

  const [allOrderData, setAllOrderData] = useState([]);
  const [searchData, setSearchData] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  let { mid } = useParams();
  useEffect(() => {
    if (permissions !== "undefined") {
      setPermissions(JSON.parse(getLocalStorage("assignMethod")));
    } else {
      setPermissions({});
    }
  }, []);

  const { status } = props;

  useEffect(() => {
    let filteringData;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringData = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }

    apiData(filteringData);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    const checkRole = getLocalStorage("role");
    mid = !mid
      ? getLocalStorage("role") == "MERCHANT"
        ? getLocalStorage("storeId")
        : ""
      : mid;

    const response = !mid
      ? dispatch(getAllOrderList(filteringData))
      : dispatch(getAllOrderListByMerchant(filteringData));

    response
      .then((data) => {
        props.setTotals(data.data.total || {});

        let result = [];
        if (data && data.data) {
          data.data.data.map(async (row, index) => {
            console.log(row, 'rowwwwwwwwwwwwwwwwwwwwwwwwwwww')
            result.push({
              productName: row?.productName || "N/A",
              consumerName: row?.consumerName ? row.consumerName : (row.consumer?.consumerName ? row.consumer.consumerName: 'N/A'),
              merchantName: row?.merchant?.preferredMerchantName || "N/A",
              productImages: row?.productImages || "N/A",
              productVariation:
                (row?.variation &&
                  row?.variation?.length > 0 &&
                  row?.variation
                    ?.map((Item, index) => Item?.value)
                    .toString()) ||
                " N/A",
              currencyName: row?.currencyName || 0,
              price: row?.price || 0,
              rating: row?.rating || 0,
              createdAt: row?.createdAt,
              updatedAt: row?.updatedAt,
              status: row?.status || "N/A",
              cancelReason: row?.shippingDetails?.reason || "N/A",
              courierName: row?.courierName || "N/A",
              trackingNo: row?.trackingInfo?.awb_no || "N/A",
              quantity: row?.quantity || "N/A",
              orderId: row?.orderId || "N/A",
              productId: row?.productId || "N/A",
              purchaseType: row?.purchaseType || "N/A",
              productType: row?.product?.productType || "N/A",
              orderType: row?.orderType || "N/A",
            });
          });
        }
        setAllOrderData(result);
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };
  // asasasas
  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const viewAction = (id, productId, item) => {
    if (props.status !== "CANCELLED") {
      if (!item && item !== undefined) {
        open_dialog(ACCESS_DENIED, {});
      } else if (item == "undefined" || item == undefined) {
        history.push(`/orders/view-order/${id}?productId=${productId || ""}`);
      } else {
        history.push(`/orders/view-order/${id}?productId=${productId || ""}`);
      }
    } else {
      history.push(
        `/orders/view-cancel-order/${id}?productId=${productId || ""}`
      );
    }
  };

  const deleteAction = (id, purchaseType) => {
    if (purchaseType == "TEAM") {
      showToast("TEAM PURCHASE Can't be deleted.", "error");
    } else {
      open_dialog(DELETE_ORDER, {
        onSubmit: getOrdersAfterDelete,
        id,
      });
    }
  };

  const getOrdersAfterDelete = (data) => {
    const newData = data.data.filter((item) => item.status !== "DELETED");
    props.afterDelete({ ...data?.total, total: newData.length });
    setAllOrderData(newData);
    // setTotalCount(data.data.length)
  };

  const toTitalCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };
  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof allOrderData[0][item] === "number") {
        let sortedData = allOrderData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setAllOrderData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderData[0][item] === "string" && !isDate) {
        let sortedData = allOrderData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });

        setTimeout(() => {
          setAllOrderData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderData[0][item] === "string" && isDate) {
        let sortedData = allOrderData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setAllOrderData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="allTeamPurchaseMain">
          <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5">
            <div className="d-flex  ">
              <div>Product</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("productName", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("productName", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Total Price</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("price", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("price", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Order Date</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("updatedAt", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("updatedAt", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Order Type</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("orderType", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("orderType", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Product Type</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("productType", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("productType", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>
                {" "}
                {props.status == "CANCELLED" ? "Request Date" : "Order Status"}
              </div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("status", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("status", "desc")}
                />
              </div>
            </div>
          </div>

          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> {props.status == "CANCELLED" ? "Reasone" : "Courier"}</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() =>
                    handleSorting(
                      props.status == "CANCELLED"
                        ? "cancelReason"
                        : "courierName",
                      "asc"
                    )
                  }
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() =>
                    handleSorting(
                      props.status == "CANCELLED"
                        ? "cancelReason"
                        : "courierName",
                      "desc"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Item</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("quantity", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("quantity", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">
            Action
          </div>
          {!allOrderData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderData.map((item, index) => (
                <div className="info" key={index}>
                  <div className="teamPurchase-orderId-detail-maindiv">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name mr-3">
                        <label className="mb-0"> Buyer:</label>{" "}
                        <span className="text-break text-capitalize">
                          {item.consumerName || "N/A"}
                        </span>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-4">
                        <label className="mb-0"> Merchant:</label>
                        <span className="text-break text-capitalize">
                          {item.merchantName || "N/A"}
                        </span>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId mr-1">
                      Order ID: {item?.orderId}
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      className="tableheadTeamPurchaseProduct"
                      onClick={() => viewAction(item?.orderId)}
                    >
                      <div className="d-flex ">
                        <Image
                          src={item.productImages || "/images/shoes.jpeg"}
                          alt={"photo"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2">
                          <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                            {item?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize">
                            Variation: {item?.productVariation}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {`${item.currencyName || "RM"}${decimalFormat(
                            item.price || 0
                          )}`}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item.updatedAt
                            ? moment(item.updatedAt).format("DD/MM/YY")
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.orderType || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.productType || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {props.status == "CANCELLED"
                            ? moment(item?.updatedAt).format(
                                "DD/MM/YY, HH:mm"
                              ) || "N/A"
                            : item?.status.replace(/_/g, " ") || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {props.status == "CANCELLED"
                            ? item?.cancelReason || "N/A"
                            : item?.courierName || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize ">
                          Tracking Id :{" "}
                          {(props.status != "CANCELLED" && item?.trackingNo) ||
                            "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {props.status == "CANCELLED"
                            ? item?.cancelReason || "N/A"
                            : item?.quantity || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="tableheadfix tableheadfix_allorder">
                      <div className="d-flex justify-content-center ">
                        <div>
                          {ablity({ model: "Order", action: "read" }) == 1 && (
                            <IconButton
                              className="pointer"
                              onClick={() =>
                                viewAction(
                                  item.orderId,
                                  item.productId,
                                  permissions?.order?.includes("update")
                                )
                              }
                            >
                              <SvgIcon
                                src="/icons/view_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          )}

                          {ablity({ model: "Order", action: "delete" }) ==
                            1 && (
                            <IconButton
                              className="pointer"
                              // disabled={item.purchaseType==="TEAM"?true:false}
                              // onClick={() => deleteAction(item.orderId, permissions?.order?.includes('delete'))}
                              onClick={() =>
                                deleteAction(item.orderId, item.purchaseType)
                              }
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default AllOrders;
